import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from "moment-timezone"
import { rrulestr } from 'rrule'
import  { useState, useEffect } from 'react';

// import CheckImage from "../images/icon_check.png"




function twoDigitFormat(str){
  return ("0"+str).slice(-2);
}
function formatUTCDate(date){
  return date.getUTCFullYear() + "-" + twoDigitFormat((date.getUTCMonth()+1)) +"-"+ twoDigitFormat(date.getUTCDate())
}
function formatDate(date){
  return date.getFullYear() + "-" + twoDigitFormat((date.getMonth()+1)) +"-"+ twoDigitFormat(date.getDate())
}
/*
* Given a list of events, return array sorted by date with all the events on each day grouped into that day.
* Also check to see if virtual recurred events have corresponding individual events from the Airtable, and fall back on information from those events if necessary.
*/

function groupByDate(eventDaysList) {
  
  const groupedData = eventDaysList.reduce((acc, event) => {
    const eventDate = event.node.data.Event_Date;
    if (!acc[eventDate]) {
      acc[eventDate] = []
    }
    acc[eventDate].push(event)
    return acc
  }, {})

  const dates = Object.keys(groupedData);

  dates.sort((a, b) => Date.parse(a) - Date.parse(b));

  const sortedData = dates.map(date => ({
    date,
    data: groupedData[date]
  }));

  const todayDate = formatDate(new Date());

  for (let i = 0; i< sortedData.length; i ++){
    let dataOnThisDay = sortedData[i].data;

    for(let j = 0; j<dataOnThisDay.length; j++ ){
      let thisEvent = dataOnThisDay[j];

      let dateOfEvent = thisEvent.node.data.Event_Date;

      if(Date.parse(dateOfEvent) < Date.parse(formatDate(new Date()))){
        thisEvent.node.hideFromCalendar = true;
      }

      if(thisEvent.node.rrule === true){
        let thisEventRecordID = thisEvent.node.data.Social_Database[0];

        for(let k = 0; k<dataOnThisDay.length; k++){
        
          let eventToCompare = dataOnThisDay[k];
          let eventToCompareRecordID = eventToCompare.node.data.Social_Database[0];

          if(eventToCompare.node.rrule !== true && 
             eventToCompareRecordID === thisEventRecordID){
            // An individual listing for this event occurs on the same day as this recurring event

            dataOnThisDay[j].node.data = dataOnThisDay[k].node.data;
            dataOnThisDay[k].node.hideFromCalendar = true;

            if(dataOnThisDay[j].node.data.Canceled){
              dataOnThisDay[k].node.hideFromCalendar = true;
              dataOnThisDay[j].node.hideFromCalendar = true;
            }
          }
        }
      } 
    }
  }

  return sortedData;

}

const SocialListTemplate = ({ data, location, pageContext }) => {


  const [eventsByDate, setEventsByDate] = useState(null);

  //let eventsByDate;

  // console.log("data", data, location, pageContext)
  const socials = data.socialList.edges;
  //const eventDays = data.calendarListGrouped.group;
  let eventDaysList = data.calendarList.edges;

  //console.log("socials", socials, "eventDaysList", eventDaysList, "eventDays", eventDays)

  /*
  * If a social has a recurrence rule, compute "virtual recurred events" from the rule
  * E.g., if an event has a recurrence rule like "every tuesday", this should make a virtual event on each tuesday between startDate and endDate
  */ 
  for(var i = 0; i < socials.length; i++){
    var thisSocial = socials[i];
    var thisRecurrenceRule = thisSocial.node.data.Recurrence_Rule;
    if(thisRecurrenceRule){

      const rule = rrulestr(thisRecurrenceRule + ";DTSTART=20221201");
      // WARNING / TODO: Refactor the line below to use the same `today` and `maxTime` value from gatsby-node.js. For now, this code is duplicated, so make sure it is consistent in both places 
      
      // const startDate = new Date(); 
      // const endDate = new Date(new Date().getTime() + 14*60*60*24*1000);
      
      const startDate = moment().subtract(1,'day').startOf('day').toDate(); 
      const endDate = moment().add(14,'days').tz('America/Los_Angeles').toDate();
    
      const virtualRecurredEvents = rule.between(startDate, endDate).map(thisDate => {

        return {node: {
            data: {
              RecordID: null,
              Description: null,
              Image: null,
              Name: thisSocial.node.data.Name,
              Social_Database: [thisSocial.node.data.RecordID],
              Paid_Bid__from_Social_Database_: thisSocial.node.data.Paid_Bid,
              Event_Date: formatUTCDate(thisDate),
              rawDate: thisDate
            },
            rrule: true
           }}
          
      }) ;

      
    
      //eventDaysList.push(...virtualRecurredEvents)
      eventDaysList = [...eventDaysList, ...virtualRecurredEvents];

    }
  }

  if (!eventsByDate) {
    setEventsByDate(groupByDate(eventDaysList));
  }

  const highlightedSocials = data.highlightedList.edges;

  return (
    <Layout location={location} title="Social List" >
      <Link className="header-link-home" to="/">
        All Regions
      </Link>
    
      <Seo
        title={`Social Dancing ${pageContext.regionPronoun} ` + pageContext.region}
        description={`See where to dance this week ${pageContext.regionPronoun} ` + pageContext.region}
        image="https://dl.airtable.com/.attachments/317d552293769d007838f38ceaeeb527/41251728/SocialDance.jpg"
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

      <h1>Social Dances {pageContext.regionPronoun} {pageContext.region}</h1>

      <p>Where to dance salsa, bachata, zouk and more in {pageContext.region}.</p>
      <p>Update! Download the <a href="https://apps.apple.com/us/app/dance-kitchen/id6443764461" target="_blank">Dance Kitchen</a> app, an interactive audio-visual experience for learning Salsa & Bachata for iPhone.</p>

      {/*
      <div className ="navigator"><span className="nav-current">Socials</span> <span className="nav-sep">|</span> <Link to={"/teachers/"+pageContext.regionSlug}>Classes</Link>
      </div>
      */}

      {highlightedSocials.length > 0 && <div style={{marginBottom: -25, marginTop: -30}}>
        <h6 style={{fontStyle: "italic", color: "#219EBC"}}>New Upcoming Events...</h6>
        {highlightedSocials.map(social => {
          const highlightedSocialData = socials.find(x => x.node.data.RecordID === social.node.data.Social_Database[0]);
          return <Link key={social.node.data.RecordID} to={`/social/${social.node.data.Social_Database[0]}/${social.node.data.RecordID}`}><img
              alt={highlightedSocialData.node.data.Name}
              className="social-image-preview"
              src={highlightedSocialData.node.data.Image[0].url}
              style={{maxWidth: "100%", width: 75, borderRadius: 35, marginRight: 30}}
            /></Link>
        })}
        </div>}

      {eventsByDate && eventsByDate.map(day => {
        
        const firstDate = day.date; //day.edges[0].node.data.Event_Date;

        const dateFormat = "dddd MM/DD/YYYY";
        const formattedDate = moment(firstDate).format(dateFormat);
        const formattedDatePreview = moment(firstDate).format("MM/DD");
        const isYesterday = moment().subtract(1,'day').format(dateFormat) === formattedDate;
        const isToday = moment().format(dateFormat) === formattedDate;
        const isTomorrow = moment().add(1,'day').format(dateFormat) === formattedDate;

       {/* console.log("first date ", firstDate)
        console.log("formatted date", formattedDate)
        console.log("yesterday", moment().subtract(1,'day').format(dateFormat))
        console.log("today", moment().format(dateFormat))


        console.log("isToday: ", isToday)
        console.log("isYesterday: ", isYesterday)
        console.log("===")*/}

        const socialsOnThisDay = day.data
            .sort((a,b) =>  {
              const bidA = (
                (a.node.data.Paid_Bid__from_Social_Database_ && a.node.data.Paid_Bid__from_Social_Database_[0]) || 0);
              const bidB = ((b.node.data.Paid_Bid__from_Social_Database_ && b.node.data.Paid_Bid__from_Social_Database_[0]) || 0)
              const comp = -(bidA-bidB);
              return comp })
            .map(socialOnThisDay => {
          const socialId = socialOnThisDay.node.data.Social_Database[0];
          const imagesOnThisDay = socialOnThisDay.node.data.Image;
          const nameOnThisDay = socialOnThisDay.node.data.Name;
          const descriptionOnThisDay = socialOnThisDay.node.data.Description;
          const social = socials.find(s => {
            return s.node.data.RecordID === socialId;
          });

          if (!social || !social.node || !social.node.data) {
            console.error('Social data is missing or incomplete:', social);
            return null; // Skip this item and continue with the next.
          }
          let pageLink = "/social/" + social.node.data.RecordID;
          let thisImageURL =  social.node.data.ImageURL || (social.node.data.Image && social.node.data.Image[0].url);
          let thisName = social.node.data.Name;
          let thisDescription = social.node.data.Description;
          let overidden = false;

          if(imagesOnThisDay !== null){
            thisImageURL = imagesOnThisDay[0].url;
            overidden = true;
          }
          if(nameOnThisDay !== null){
            thisName = nameOnThisDay;
            overidden = true;
          }
          if(descriptionOnThisDay !== null){
            thisDescription = descriptionOnThisDay;
            overidden = true;
          }

          if(overidden && socialOnThisDay.node.data.RecordID){
            // If there are any calendar event day overrides, link to the event day page
            pageLink = pageLink + "/" + socialOnThisDay.node.data.RecordID;
          }

          if(socialOnThisDay.node.hideFromCalendar){
            //deleted event
            return null;
          }

          return <li key={social.node.data.RecordID}>
              <article
                className="post-list-item social-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Link to={pageLink} itemProp="url">
                <header>
                  <h2>
                      <span itemProp="headline">{thisName}</span>
                  </h2>
                  {social.node.data.Confirmed &&
                    <div>
                      <span className="event-confirmed">✅ It's on! This event is confirmed by the host.</span>
                    </div>
                  }
                </header>
                <section>

                  <div className="dance-row">
                    <div className="dance-col">
                      <img
                        alt={social.node.data.Name}
                        className="social-image-preview"
                        src={thisImageURL}
                        style={{maxWidth: "100%"}}
                      />
                    </div>

                    <div className="dance-col">
                      {social.node.data.Dance_Styles.map(style => {
                        return <span key={style} className="dance-style">{style}</span>
                      })}
                    </div>
                  </div>

                  <p itemProp="description" className="social-dance-description">{thisDescription}</p>
                  <p className="social-dance-location">{social.node.data.Name__from_Venue_}</p>
                  <span><em className="social-time-description"><span className="social-time">{social.node.data.Start_Time}</span> {social.node.data.Day} ({formattedDatePreview})</em></span>
                </section>
                </Link>
              </article>
            </li>
        });


        return <div key={formattedDate} className={["social-day-container", "display-status-today-" + isToday, "display-status-yesterday-" + isYesterday]} style={{
          display: "block" // ((isYesterday || is2DayAgo) ? "none": "block")
        }}>

          {!day.data.every(x => x.node.hideFromCalendar) && <div>
              <h5 className="social-day">{
              (isYesterday ? "Yesterday, " : "") +
              (isToday ? "Today, " : "") +
              (isTomorrow ? "Tomorrow, " : "") +
              formattedDate
            }</h5>
            <ol style={{ listStyle: `none` }}>
            {socialsOnThisDay}
            </ol> 
          </div>}
          
        </div>
      })}



      </article>
    </Layout>
  )
}

export default SocialListTemplate


export const pageQuery = graphql`
  query SocialListByRegion(
    $regionSlug: String!
    $today: Date
    $maxTime: Date
  ) {
    site {
      siteMetadata {
        title
      }
    }
    calendarListGrouped: allAirtable(filter: {table: {eq: "Event Calendar"},data: {Event_Date: {gte: $today, lte: $maxTime}, Linked_region_slug__from_Social_Database_: {eq: $regionSlug}}}, sort: {fields: data___Event_Date, order: ASC}) {
      group(field: data___Event_Date) {
        edges {
          node {
            id
            data {
              RecordID
              Event_Date
              Social_Database
              Image { url }
              Name
              Description
              Paid_Bid__from_Social_Database_
            }
          }
        }
      }
    }
    calendarList: allAirtable(filter: {table: {eq: "Event Calendar"},data: {Event_Date: {gte: $today, lte: $maxTime}, Linked_region_slug__from_Social_Database_: {eq: $regionSlug}}}, sort: {fields: data___Event_Date, order: ASC}) {
        
          edges {
            node {
              
              data {
                RecordID
                Event_Date
                Social_Database
                Image { url }
                Name
                Canceled
                Description
                Paid_Bid__from_Social_Database_
              }
            }
            
          }
        }
    socialList: allAirtable(filter: {table: {eq: "Social Database"}, data: {Status: {eq: "Live"}, Linked_region_slug: {eq: $regionSlug}}}) {
      edges {
        node {
          data {
            RecordID
            Name
            Description
            Dance_Styles
            Day
            Recurrence_Rule
            Start_Time
            Venue
            Confirmed
            Paid_Bid
            Name__from_Venue_
            ImageURL
            Image {
             url
            }
          }
        }
      }
    }
    highlightedList: allAirtable(filter: {table: {eq: "Event Calendar"},data: {HighlightedEvent: {eq: "True"}, Linked_region_slug__from_Social_Database_: {eq: $regionSlug}}}, sort: {fields: data___Event_Date, order: ASC}) {

      edges {
        node {
          id
          data {
            RecordID
            Event_Date
            Social_Database
            Image { url }
            Name
            Description
            Paid_Bid__from_Social_Database_
          }
        }
      }

    }

  }
`
